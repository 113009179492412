.react-datepicker__day--selected {
    background-color: var(--color-primary);
}
.react-datepicker__day--selected:hover {
    background-color: var(--color-primary);
    font-weight: bold;
}
.react-datepicker__day--keyboard-selected {
    background-color: var(--color-primary);
}
.react-datepicker__day--keyboard-selected:hover {
    background-color:var(--color-primary);
    font-weight: bold; 
}