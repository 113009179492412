@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: switzer;
  src: url("./fonts/Switzer-Regular.otf") format("opentype");
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input-error[type='checkbox'] {
  outline: 0.5px solid red;
  outline-style: auto;
}

.input-error[type='checkbox']:focus {
  outline: 0.5px solid red;
  outline-style: auto;
}

.react-loading-skeleton {
  line-height: unset;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: var(--color-primary) !important;
  --rdp-background-color: #e7edff;
  --rdp-accent-color-dark: var(--color-primary) !important;
  --rdp-background-color-dark: #180270;
  --rdp-outline: 2px solid var(--rdp-accent-color);
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  --tw-ring-color: var(--color-primary) !important;
  margin: 1em;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.modal-bg-color {
  background-color: rgba(180, 180, 180, 0.6);
}

/* angola bg bug fix */
.prefix__st0 {
  fill: #D80027 !important;
}